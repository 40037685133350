import { CircularProgress } from '@mui/material';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilState } from 'recoil';
import MaintenancePage from 'src/components/Maintenance/MaintenancePage';
import SideSteps from 'src/components/Steps/SideSteps';
import StepsMobile from 'src/components/Steps/StepsMobile';
import Layout from 'src/components/layout';
import useMaintenance from 'src/hooks/useMaintenance';
import SignupLayoutHeader from 'src/layouts/components/SignupLayoutHeader';
import SignupMobileTopbar from 'src/layouts/components/SignupMobileTopbar';
import { Steps, pathToStep, stepsAtom } from 'src/recoil/steps/atoms';
import 'src/services/AmplifyService';
import {
  CustomToastifyContainer,
  LoaderWrapper,
  SignUpLayoutBackgroundWrapper,
  SignUpLayoutBlueBackground,
  SignUpLayoutBodyWrapper,
  SignUpLayoutStepContainer,
  StepWrapper,
  StyledOverlay,
} from 'src/styles/signup/signupSteps';

interface SignUpProps {
  children: React.ReactElement;
  path: string;
}

interface LocaleNode {
  ns: string;
  data: string;
  language: string;
}

interface LocaleQueryResult {
  locales: {
    edges: Array<{
      node: LocaleNode;
    }>;
  };
}

export const SignUpLayout: React.FC<SignUpProps> = ({ path, children }) => {
  const [token, setToken] = useState<string>('');
  const { isOnMaintenance } = useMaintenance();
  const [stepsState, setStepsState] = useRecoilState(stepsAtom);
  const { currentUser, currentStep } = stepsState;
  const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);

  useEffect(() => {
    // Initialize reCAPTCHA key after component mounts
    if (typeof window !== 'undefined') {
      const key = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY;
      if (key) {
        setRecaptchaKey(key);
      } else {
        console.error('Missing GATSBY_GOOGLE_RECAPTCHA_SITE_KEY environment variable');
      }
    }
  }, []);

  useEffect(() => {
    if (isOnMaintenance && window.location.pathname !== '/maintenance') {
      navigate('/maintenance', { replace: true });
    }
  }, [isOnMaintenance]);

  const derivedStep = pathToStep(path);
  const effectiveStep = typeof window === 'undefined' ? derivedStep : currentStep;

  useEffect(() => {
    if (typeof window !== 'undefined' && derivedStep && derivedStep !== currentStep) {
      setStepsState((prev) => ({
        ...prev,
        currentStep: derivedStep,
      }));
    }
  }, [path, derivedStep, currentStep, setStepsState]);

  const { locales } = useStaticQuery<LocaleQueryResult>(graphql`
    query SignupLayoutQuery {
      locales: allLocale(filter: { ns: { in: ["translation", "about-page"] }, language: { eq: "he" } }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `);

  useEffect(() => {
    const handlePopState = async (): Promise<void> => {
      await navigate('/');
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  const onVerify = useCallback((token: string): void => {
    setToken(token);
  }, []);

  const shouldRenderStep = useMemo((): boolean => {
    return (
      (currentUser?.id !== '' ||
        effectiveStep === Steps.WELCOME ||
        effectiveStep === Steps.AUTHENTICATION ||
        effectiveStep === Steps.VERIFICATION) &&
      currentStep === effectiveStep
    );
  }, [currentUser, currentStep, effectiveStep]);

  // Render base layout content
  const layoutContent = (
    <>
      <SignupMobileTopbar />
      <SignUpLayoutBackgroundWrapper>
        <SignUpLayoutBlueBackground />
      </SignUpLayoutBackgroundWrapper>

      <SignUpLayoutBodyWrapper>
        <SideSteps activeStep={effectiveStep} />
        <StepsMobile activeStep={effectiveStep} />

        <SignUpLayoutStepContainer>
          <SignupLayoutHeader />
          <SignUpLayoutLoaderComponent />
          {shouldRenderStep ? <StepWrapper>{children}</StepWrapper> : null}
        </SignUpLayoutStepContainer>
      </SignUpLayoutBodyWrapper>

      <CustomToastifyContainer>
        <ToastContainer />
      </CustomToastifyContainer>
    </>
  );

  // Wrap everything in Layout component
  const content = <Layout>{layoutContent}</Layout>;

  // Only render GoogleReCaptchaProvider when key is available
  if (!recaptchaKey) {
    return content;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
      }}>
      {content}
      <GoogleReCaptcha onVerify={onVerify} />
    </GoogleReCaptchaProvider>
  );
};

const SignUpLayoutLoaderComponent: React.FC = () => {
  const [stepsState] = useRecoilState(stepsAtom);
  const { loading } = stepsState;

  return loading ? (
    <StyledOverlay>
      <LoaderWrapper>
        <CircularProgress size={60} />
      </LoaderWrapper>
    </StyledOverlay>
  ) : null;
};

export default SignUpLayout;
